<template>
  <v-container>
    <v-row dense>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="tertiary" dark class="dashboard-card">
          <v-card-title class="headline"
            ><v-icon>mdi-file-outline</v-icon>
            <div class="title">Aufträge</div></v-card-title
          >
          <v-card-text>
            <div>
              Sie haben
              <span class="highlighter">{{ assignments.length }}</span>
              unbearbeitete Aufträge.
            </div>
            <div v-if="role != 'worker'">
              Davon sind
              <span class="highlighter">{{ transmitted }} </span> übermittelte
              Aufträge
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2"
              @click="$router.push('/assignments')"
              dark
              small
              color="primary"
            >
              Aufträge anzeigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="wartungen && role != 'worker'">
        <v-card color="tertiary" dark class="dashboard-card">
          <v-card-title class="headline"
            ><v-icon>mdi-format-list-checkbox</v-icon>
            <div class="title">Wartungen</div></v-card-title
          >
          <v-card-text>
            <div v-if="role == 'admin' || role == 'pl'">
              Sie haben
              <span class="highlighter">{{ wartungen.length }}</span>
              unzugewiesene Wartungen.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2"
              dark
              @click="$router.push('/wartungen')"
              small
              color="primary"
            >
              Wartungen anzeigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="tertiary" dark class="dashboard-card">
          <v-card-title class="headline"
            ><v-icon>mdi-playlist-check</v-icon>
            <div class="title">Arbeitsnachweise</div></v-card-title
          >
          <v-card-text>
            <div>
              Sie haben
              <span class="highlighter">{{ arbeitsnachweise.length }}</span>
              unbearbeitete Arbeitsnachweise.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2"
              dark
              small
              @click="$router.push('/arbeitsnachweise')"
              color="primary"
            >
              Arbeitsnachweise anzeigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!--  <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="tertiary" dark class="dashboard-card">
          <v-card-title class="headline"
            ><v-icon>mdi-clipboard-check</v-icon>
            <div class="title">Aufgaben</div></v-card-title
          >
          <v-card-text>
            <div>
              Sie haben
              <span class="highlighter">{{ todos.length }}</span> Übermittelte
              Aufgaben.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" dark small color="#ff9b35">
              Aufgaben anzeigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col> -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="schichtplan">
        <v-card color="tertiary" dark class="dashboard-card">
          <v-card-title class="headline"
            ><v-icon>mdi-calendar</v-icon>
            <div class="title">Schichtplan</div></v-card-title
          >
          <v-card-text>
            <div class="text-left">
              Ihre Arbeitsplanung für heute
              <ul>
                <li>10.00 - 12.00 Muster GmbH</li>
                <li>13.00 - 15.00 Gotham City GmbH</li>
              </ul>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" dark small color="#ff9b35">
              Schichtplan anzeigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="#004e63" dark class="dashboard-card">
          <v-card-title class="headline"
            ><v-icon>settings</v-icon>
            <div class="title">Einstellungen</div></v-card-title
          >
          <v-card-text>
            <div>
              Hier können Sie ihre Einstellungen bearbeiten
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" dark small color="#ff9b35">
              Einstellungen anzeigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      selectedItem: "",
      users: [],
      schichtplan: null,
      wartungen: [],
      todos: [],
      arbeitsnachweise: [],
      assignments: [],
      transmitted: 0,
      search: "",
      confirm: false,
      tab: "",
      items: [],
      isLoading: false,
      model: null,
      search: null,
      rules: {
        required: (value) => !!value || "Erforderlich.",
        requiredarray: (value) => !!value.length || "Erforderlich.",
      },
    };
  },
  computed: {
    role() {
      return this.$store.getters.user.role;
    },
    online() {
      return this.$store.getters.online;
    },
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData() {
      if (this.online) {
        var self = this;
        this.$http({
          method: "post",
          url: "getDashboardData.php",
        })
          .then((response) => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (response.data && response.data.success) {
              if (response.data.wartungen) {
                self.wartungen = response.data.wartungen;
              } else {
                self.wartungen = null;
              }
              if (response.data.assignments) {
                const assignments = response.data.assignments;
                self.assignments = assignments;
                let transmitted = 0;
                assignments.forEach((a) => {
                  if (a.status == "2") {
                    transmitted++;
                  }
                });
                this.transmitted = transmitted;
              }
              if (response.data.finalized) {
                self.finalizedwartungen = response.data.finalized;
              } else {
                self.finalizedwartungen = null;
              }
              if (response.data.arbeitsnachweise) {
                self.arbeitsnachweise = response.data.arbeitsnachweise;
              }
              /*    if (response.data.users) {
                self.users = response.data.users;
              }
              if (response.data.leiters) {
                self.leiters = response.data.leiters;
              } */
            }
          })
          .catch(function (error) {});
      } else {
        const data = this.$store.getters.offlinedata;
        if (data.todowartungen) {
          this.todowartungen = data.todowartungen;
        }
        /*  if (data.assignments) {
          this.assignments = data.assignments;
        } */
        if (data.nextwartungen) {
          this.nextwartungen = data.nextwartungen;
        }
        if (data.finalizedwartungen) {
          this.finalizedwartungen = data.finalizedwartungen;
        }
        if (data.users) {
          this.users = data.users;
        }
        if (data.leiters) {
          this.leiters = data.leiters;
        }
      }
    },
    getClass(value) {
      if (!value) {
        return "";
      }
      const date = new Date(value);
      const now = new Date();
      const diff = date - now;
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      if (diffDays < 30) {
        return (
          '<span class="darkred-highlight">' +
          this.$moment(value).format("DD.MM.YYYY") +
          "</span>"
        );
      }
      if (diffDays < 60) {
        return (
          '<span class="orange-highlight">' +
          this.$moment(value).format("DD.MM.YYYY") +
          "</span>"
        );
      }
      return (
        '<span class="green-highlight">' +
        this.$moment(value).format("DD.MM.YYYY") +
        "</span>"
      );
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    /* changeWorker(id) {
      const user = this.users.find(u => {
        return u.id === id;
      });
      if (user) {
        this.userid = id;
        this.selectedItem.userid = id;
        this.selectedItem.username = user.name;
      } else {
        this.userid = "";
        this.selectedItem.userid = undefined;
        this.selectedItem.username = undefined;
      }
    }, */
    openWorkerDialog(item) {
      this.selectedItem = {
        customerid: item.customerid,
        userid: item.userid,
        wid: item.wid,
        status: item.status,
        username: item.username,
        id: item.id,
      };
      const ids =
        item.userid && item.userid !== "" ? JSON.parse(item.userid) : [];
      let userids = [];
      ids.forEach((id) => {
        userids.push(id + "");
      });
      if (item.assignmentid) {
        this.model = {
          id: item.assignmentid,
          assignmentnumber: item.assignmentnumber,
        };
        this.items.push({
          id: item.assignmentid,
          assignmentnumber: item.assignmentnumber,
        });
      }
      this.userids = userids;
      this.dialog = true;
    },
    checkAssignment() {
      let newAssignment = JSON.parse(JSON.stringify(this.newAssignment));
      let formData = new FormData();
      formData.append("id", newAssignment.assignmentnumber);
      this.$http({
        method: "post",
        url: "getAssignmentByNumber.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            if (response.data.notFound) {
              this.addAssignment(false);
            }
            if (response.data.info) {
              this.newAssignment.id = response.data.info.id;
              this.confirm = true;
            }
          } else {
          }
        })
        .catch(function (error) {
          error;
        });
    },
    addAssignment(edit) {
      this.confirm = false;
      let newAssignment = JSON.parse(JSON.stringify(this.newAssignment));
      newAssignment["creator"] = this.$store.getters.user.name;
      const item = this.selectedItem;
      const userids = this.userids;
      let usernames = [];
      let ids = [];
      if (userids) {
        userids.forEach((id) => {
          const user = this.users.find((u) => {
            return u.id === id;
          });
          if (user && user.name && user.id) {
            usernames.push(user.name);
            ids.push(parseInt(user.id));
          }
        });
      }
      newAssignment["usernames"] = usernames.join(", ");
      newAssignment["userids"] = ids;
      newAssignment["customerid"] = item.customerid;
      const plids = newAssignment["plids"];
      let plnames = [];
      let pids = [];
      if (plids) {
        plids.forEach((id) => {
          const pl = this.leiters.find((u) => {
            return u.id === id;
          });
          if (pl && pl.name && pl.id) {
            plnames.push(pl.name);
            pids.push(parseInt(pl.id));
          }
        });
      }
      newAssignment["plnames"] = plnames.join(", ");
      newAssignment["plids"] = pids;
      newAssignment["standortid"] = "";
      const date = Math.round(new Date().getTime() / 1000);
      newAssignment["time"] = date;
      let formData = new FormData();
      newAssignment["status"] = 1;
      formData.append("assignment", JSON.stringify(newAssignment));
      formData.append("edit", edit);
      this.$http({
        method: "post",
        url: "saveAssignment.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            const msg = "Der Auftrag wurde erfolgreich erstellt";
            const color = "success";
            this.clearAssignment();
            this.createAssignment = false;
            this.dialog = false;
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Der Auftrag konnten nicht erstellt werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          error;
        });
    },
    assignWorker() {
      if (this.$refs.form.validate()) {
        const userids = this.userids;
        let ids = [];
        let usernames = [];
        userids.forEach((id) => {
          const user = this.users.find((u) => {
            return u.id === id;
          });
          if (user && user.name && user.id) {
            usernames.push(user.name);
            ids.push(parseInt(user.id));
          }
        });
        let item = this.selectedItem;
        ids = ids.length ? JSON.stringify(ids) : "[]";
        usernames = usernames.join(", ");
        item.username = usernames;
        item.userid = ids;
        const userblockid =
          item.wid && item.status && item.status !== "3" ? item.wid : "";

        let formData = new FormData();
        formData.append("blockid", item.id);
        formData.append("userblockid", userblockid);
        formData.append("userids", ids);
        formData.append("usernames", usernames || "");
        if (this.model && this.model.id) {
          formData.append("assignmentid", this.model.id);
        }
        this.$http({
          method: "post",
          url: "updateUserBlock.php",
          data: formData,
          config: {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Accept: "application/json",
            },
          },
        })
          .then((response) => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (response && response.data && response.data.success) {
              let wartungen = this.nextwartungen;
              wartungen.forEach((w) => {
                if (w.id === item.id) {
                  w.userid = item.userid;
                  w.username = item.username;
                }
              });

              let msg = "";
              if (this.selectedItem && this.selectedItem.username) {
                msg =
                  "Die Wartung wurde dem Service-Techniker " +
                  this.selectedItem.username +
                  " zugewiesen.";
              } else {
                msg = "Die Zuweisung des Service-Technikers wurde entfernt.";
              }
              const color = "success";
              this.$store.dispatch("snackbar", { msg, color });
              /*   if (this.createAssignment) {
                this.checkAssignment();
              } else { */
              this.dialog = false;
              this.model = null;
              // }
            } else {
              const msg =
                "Die Wartung konnte nicht dem Service-Techniker " +
                this.selectedItem.username +
                " zugewiesen werden. Bitte versuchen Sie es erneut.";
              const color = "error";
              this.$store.dispatch("snackbar", { msg, color });
            }
          })
          .catch(function (error) {
            error;
          });
      }
    },
    clearAssignment() {
      this.newAssignment = {
        date: "",
        assignmentnumber: "",
        customerid: "",
        standortid: "",
        plids: "",
        plnames: "",
        info: "",
        userids: "",
        userames: "",
        comment: "",
      };
    },
  },
};
</script>
